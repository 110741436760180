import {Amplify} from 'aws-amplify';

import {withAuthenticator} from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

import awsExports from './iam-users-pool';

Amplify.configure(awsExports);

function App({signOut, user}) {
    console.log(user);
    console.log("---- ID TOKEN -----");
    console.log(user.storage[user.keyPrefix + "." + user.username + ".idToken"]);
    return (
        <>
            <h1>Hello {user.username}</h1>
            <button onClick={signOut}>Sign out</button>
            <h2>ID TOKEN</h2>
            <code>{user.storage[user.keyPrefix + "." + user.username + ".idToken"]}</code>
            <h2>ACCESS TOKEN</h2>
            <code>{user.storage[user.keyPrefix + "." + user.username + ".accessToken"]}</code>
            <h2>USER DATA</h2>
            <code>{user.storage[user.keyPrefix + "." + user.username + ".userData"]}</code>
        </>
    );
}

export default withAuthenticator(App);