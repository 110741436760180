const iamUsersPool = {
    "Auth": {
        "region": "us-east-1",
        "userPoolId": "us-east-1_TmdC9WPHK",
        "userPoolWebClientId": "462940e47vd5251kgce3as49ck",
        "clientMetadata": {
            "iamAuthorityTableName": "iam-authority-cmt-sandbox"
        }
    }
}

export default iamUsersPool;